/* PageNotFound.module.css */
@import url("https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/riotsans.css");

.notFoundWrapper {
  font-family: "Riot Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 64vh;
  text-align: center;
}

.notFoundWrapper:not(:lang(ja-jp)) {
  text-transform: uppercase;
}

.errorCode {
  font-size: 250px;
  line-height: 1;
  color: #eb0029;
  font-weight: bold;
  margin: 0;
  @media screen and (max-width: 1024px) {
    font-size: 125px;
  }
  @media screen and (max-width: 417px) {
    font-size: 90px;
  }
}

.notFoundTitle {
  font-size: 66px;
  color: #333;
  @media screen and (max-width: 1024px) {
    font-size: 40px;
  }
  @media screen and (max-width: 417px) {
    font-size: 28px;
  }
}

.notFoundText {
  font-size: 24px;
  color: #666;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
}
